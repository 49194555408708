<template>
  <div
    class="deal is-elevated-1 box"
    v-dismissible="`facebook-ad`"
    v-only-locales="['en-gb']"
  >
    <div class="deal-merchant">
      <div class="deal-logo" style="padding: 30px">
        <i class="fab fa-facebook-f fa-3x has-text-facebook"></i>
      </div>
    </div>

    <div class="deal-description">
      <div class="has-text-grey has-text-weight-semibold" v-t="`deals.ads.tip`"></div>
      <div class="is-size-5 has-text-weight-semibold" v-t="`deals.ads.facebook.main`"></div>
      <div class="has-text-grey is-size-7" v-t="`deals.ads.facebook.info`"></div>
    </div>

    <div class="deal-action">
      <a
        class="deal-button button is-facebook has-text-weight-semibold"
        :href="$config.facebookCommunity.url"
        target="_blank"
        ref="noopener"
        v-t="`deals.ads.facebook.cta`"
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  //
}
</script>
