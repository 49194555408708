<template>
  <div
    class="deal is-elevated-1 is-hidden-mobile box"
    v-dismissible="`amazon-deal-finder-ad`"
    v-only-locales="['en-gb']"
  >
    <div class="deal-merchant">
      <div class="deal-logo" style="padding: 20px">
        <img v-lazy="`$/images/amazon-deal-finder.png`" alt="Amazon Deal Finder">
      </div>
    </div>

    <div class="deal-description">
      <div class="has-text-grey has-text-weight-semibold" v-t="`deals.ads.tip`"></div>
      <div class="is-size-5 has-text-weight-semibold" v-t="`deals.ads.amazon.main`"></div>
      <div class="has-text-grey is-size-7" v-t="`deals.ads.amazon.info`"></div>
    </div>

    <div class="deal-action">
      <router-link
        class="deal-button button is-secondary has-text-weight-semibold"
        :to="{ name: 'amazondealfinder' }"
        v-t="`deals.ads.amazon.cta`"
      ></router-link>
    </div>
  </div>
</template>

<script>
export default {
  //
}
</script>
