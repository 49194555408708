<template>
  <div
    class="deal is-elevated-1 box"
    v-dismissible="`messenger-ad`"
    v-only-locales="['en-gb']"
  >
    <div class="deal-merchant">
      <div class="deal-logo" style="padding: 30px">
        <i class="fab fa-facebook-messenger fa-3x has-text-messenger"></i>
      </div>
    </div>

    <div class="deal-description">
      <div class="has-text-grey has-text-weight-semibold" v-t="`deals.ads.tip`"></div>
      <div class="is-size-5 has-text-weight-semibold" v-t="`deals.ads.messenger.main`"></div>
      <div class="has-text-grey is-size-7" v-t="`deals.ads.messenger.info`"></div>
    </div>

    <div class="deal-action">
      <a
        class="deal-button button is-messenger has-text-weight-semibold"
        :href="`${$config.messengerBot.url}${this.ref}`"
        target="_blank"
        ref="noopener"
        v-t="`deals.ads.messenger.ctas.sign-up`"
      ></a>
    </div>
  </div>
</template>

<script>
import Utm from '@/core/services/Utm'

export default {
  computed: {
    ref () {
      return `deals-website--${(Utm.get().utm_source || 'organic')}`
    }
  }
}
</script>
