<template>
  <main-layout>
    <section class="section is-medium" role="search">
      <div class="container has-text-centered">
        <h1 class="title is-1 has-text-weight-black">
          <span class="has-text-secondary" v-t="`home.relax`"></span>
          &nbsp;
          <span v-t="`deals.finding-voucher`"></span>
        </h1>

        <div class="columns is-centered">
          <div class="column is-6">
            <search></search>
          </div>
        </div>
      </div>
    </section>

    <main class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-8">
            <facebook-ad></facebook-ad>
            <messenger-ad></messenger-ad>
            <amazon-deal-finder-ad></amazon-deal-finder-ad>

            <div class="spacer is-medium" v-only-locales="['en-gb']"></div>

            <h2 class="title is-4" v-t="`deals.featured-deals`"></h2>

            <deal v-for="(deal, i) in displayedDeals" :key="deal.id" :deal="deal" :index="i"></deal>

            <div class="has-text-centered has-margin-4">
              <p-button
                text
                bold
                class="has-text-primary"
                v-show="dealsLimit < featuredDeals.length"
                @click="showMoreDeals()">
                  <span v-t="`deals.show-more`"></span>
                  &nbsp;<i class="fas fa-angle-down"></i>
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </main>

  </main-layout>
</template>

<script>
import { mapState } from 'vuex'
import Deal from './components/Deal'
import Search from '@/views/partials/Search'
import FacebookAd from './components/FacebookAd'
import MessengerAd from './components/MessengerAd'
import AmazonDealFinderAd from './components/AmazonDealFinderAd'

export default {
  components: { AmazonDealFinderAd, Deal, FacebookAd, MessengerAd, Search },

  data () {
    return {
      dealsLimit: 5
    }
  },

  computed: {
    ...mapState({
      featuredDeals: state => state.featuredVouchers
    }),

    displayedDeals () {
      return this.featuredDeals.slice(0, this.dealsLimit)
    }
  },

  methods: {
    showMoreDeals () {
      this.dealsLimit += 10
    }
  }
}
</script>

<style lang="sass" scoped>
h1.title
  margin-bottom: 2em
</style>
